.custom-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.f-bigrow {
    flex-wrap: wrap;
    display: flex;
}

.f-row {
    width: 100%;
    display: flex;
}
.f-d {
    display:flex;
}
.f-wrap {
    flex-wrap:wrap;
}
.f-jc {
    justify-content:center;
}
.f-s0{
    flex-shrink:0;    
}   
.f-dcol{
    flex-direction: column;
}
.text-center {
    text-align: center;
}

.low-margin {
    margin-top: 0.2em;
    margin-bottom: 0.2em;
}
.as-h1 {
    font-weight: normal;
    font-size: 6em;
    color: #616263;
}
.as-h2 {
    font-weight: normal;
    font-size: 3em;
    color: #616263;
}
/*COLORS*/
.alternate-color {
    background-color: #d8a06d;
    opacity: 0.5;
}
/*COLORS*/
/*FONTS START*/
.white {    
    color: white;    
}
.distantStroke {
    font-family: Motterdam;
}

.sophisticated {
    font-family: Sophisticated;  
}
.runday{
    font-family: Runday;
}
.runday-italic {
    font-family: RundayItalic;
}
/*FONTS END*/
.keen-slider {
    /*justify-content: center;*/
    align-items: center;
    height: 100%;
}

[class^="number-slide"],
[class*=" number-slide"] {    
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3em;
    color: #010203;
    font-weight: 500;
    height: 50vw;
    max-height: 100vh;
    
}

.number-slide {      
    transition: font-size 0.3s, margin-bottom 0.3s, color 0.3s;
    min-width: 20vw;
    max-width: 20vw;
}
.number-slide-active {
    font-size: 12em;
    
}
.slider-container {
    display:flex;
    align-items:center;    
    height: 300px;    
}
.slider-highlight {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width:50vw;
    height:50vw;
    max-width: 200px;
    max-height:200px;
    background-color:rgb(255, 255, 255, 0.7);
    z-index:0;    
    border-radius: 50%;
    border: 2px solid #c3c3c3;
}



