@font-face {
    font-family: illusias;
    src: url("../../../public/fonts/illusias.woff") format('woff');
}

@font-face {
    font-family: oregon;
    src: url("../../../public/fonts/oregonLdoBook.woff") format('woff');
}

@font-face {
    font-family: poppins-sb;
    src: url("../../../public/fonts/poppinsSemibold.woff") format('woff');
}

@font-face {
    font-family: poppins-m;
    src: url("../../../public/fonts/poppinsMedium.woff") format('woff');
}

@font-face {
    font-family: poppins-r;
    src: url("../../../public/fonts/Poppins-Regular.woff") format('woff');
}

$logo-color: #253434;

$primary-background-color: #F7EDE6;
$alternate-background-color: #D7DEE6;

/*$contrast-background-color: #363D10;*/
$contrast-background-color: #52636F;
/*$contrast-background-color: #666F52;*/

$footer-background-color: #EAD9C9;

$primary-text-color: #253434;
$secondary-text-color: #9D7336;
$tertiary-text-color: #B67D2C;

$primary-title-color: #C5AB87;
$secondary-title-color: #FFFFFF;
$tertiary-title-color: #D6A663;

$head-button-color: #C1C9D5;

$primary-button-color: #253434;
$primary-button-border: #253434;
$primary-button-text: #FFFFFF;

$secondary-button-color: transparent;
$secondary-button-border: #6F7863;
$secondary-button-text: #6F7863;

$warning-color: #B67D2C;
$danger-color: #c60606;

%body-rem {
    font-size: 1rem;

    @media screen and (max-width: 575.98px) {
        font-size: 0.8rem;
    }

    @media screen and (max-width: 767.98px) {
        font-size: 0.8rem;
    }
}


%big-title-rem {
    font-size: 5.5rem;

    @media screen and (max-width: 375.98px) {
        font-size: 2rem;
    }

    @media screen and (min-width: 376px) and (max-width: 575.98px) {
        font-size: 3rem;
    }

    @media screen and (min-width: 576px)and (max-width: 767.98px) {
        font-size: 3rem;
    }
}

%title-rem {
    font-size: 5rem;

    @media screen and (max-width: 575.98px) {
        font-size: 2.5rem;
    }

    @media screen and (max-width: 767.98px) {
        font-size: 2.5rem;
    }
}

%small-title-rem {
    font-size: 4.5rem;

    @media screen and (max-width: 575.98px) {
        font-size: 2.5rem;
    }
}

%pretitle-rem {
    font-size: 1.2rem;

    @media screen and (max-width: 575.98px) {
        font-size: 0.8rem;
    }

    @media screen and (max-width: 767.98px) {
        font-size: 0.8rem;
    }
}

%subtitle-rem {
    font-size: 1.2rem;

    @media screen and (max-width: 575.98px) {
        font-size: 0.8rem;
    }

    @media screen and (max-width: 767.98px) {
        font-size: 0.8rem;
    }
}

%box-number-rem {
    font-size: 12rem;

    @media screen and (max-width: 575.98px) {
        font-size: 10rem;
    }

    @media screen and (max-width: 767.98px) {
        font-size: 10rem;
    }
}

%box-title-rem {
    font-size: 4rem;

    @media screen and (max-width: 575.98px) {
        font-size: 3rem;
    }

    @media screen and (max-width: 767.98px) {
        font-size: 3rem;
    }
}

%box-map-rem {
    font-size: 1.2rem;

    @media screen and (max-width: 575.98px) {
        font-size: 1rem;
    }

    @media screen and (max-width: 767.98px) {
        font-size: 1rem;
    }
}

%stepper-text-rem {
    font-size: 1.5rem;
}


%button-rem {
    font-size: 0.8rem;
}

%form-switch-w-rem {
    width: 2.5rem;
}

%form-switch-h-rem {
    height: 1.5rem;
}

%form-check-h-rem {
    height: 1.5rem;
}

%form-check-w-rem {
    width: 1.5rem;
}

.t-logo {
    font-family: illusias;
    font-size: 40px;
    color: $primary-text-color;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: 0px;
    line-height: 40px;
}

.t-primary-button {
    font-family: poppins-r;
    text-transform: uppercase;
    color: $primary-button-text !important;
    border-radius: 50px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: $primary-button-color !important;
    border-color: $primary-button-border !important;
    @extend %button-rem;
    min-width: 120px;

    &:hover {
        background-color: $primary-button-color !important;
        border-color: $primary-button-border !important;
        color: $primary-button-text !important;
    }

    &:active {
        background-color: $primary-button-color !important;
        border-color: $primary-button-border !important;
        color: $primary-button-text !important;
        opacity: 0.8;
    }

    &.danger {
        background-color: $danger-color;
        border-color: $danger-color;

        &:hover {
            background-color: #ff5050 !important;
            border-color: #ff5050 !important;
            color: $primary-button-text !important;
        }
    }
}

.t-link {
    color: $primary-text-color;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.t-alternate-button {
    font-family: poppins-r;
    text-transform: uppercase;
    color: $primary-text-color;
    border-radius: 50px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: transparent;
    border-color: $secondary-button-border;
    @extend %button-rem;
    min-width: 120px;

    &:hover {
        background-color: $primary-button-color;
        border-color: $primary-button-border;
        color: $primary-button-text;
    }

    &:active {
        background-color: $primary-button-color !important;
        border-color: $primary-button-border !important;
        color: $primary-button-text !important;
        opacity: 0.8;
    }
}