.lp-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bg-image {
    background-image: url('../../content/images/countdown_background.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.bg-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,1);
    transition: 1s ease-out all;
    z-index: 100;
}

.bg-overlay-unlocked {
    background-color: rgba(0,0,0,0.45);
    z-index: 1;
}

.content {
    z-index: 10;
    color: white;
    font-size: 60px;
    font-family: DistantStroke;
    font-weight: 800;
    text-align: center;
}
